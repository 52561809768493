import React, { Component } from 'react';
import {
    Button,
    CardContent,
    Typography,
    TextField,
    Grid,
    Card,
    CardActions,
    Paper,
    LinearProgress,
    Link
} from '@material-ui/core';
import ReactReadMoreReadLess from "react-read-more-read-less";
import GoogleMaps from './GoogleMapsAutocomplete';

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        this.state = {
            searchResults: [],
            keywords: null,
            officeNumber: parseInt(this.props.match.params.officeNumber),
            radius: "",
            latitude: null,
            longitude: null,
            selectRadiusOpen: false,
            loading: true,
            address: "",
            isMapsLibLoaded: false,
        }

        this.setSelectedKeywords = this.setSelectedKeywords.bind(this);
    }

    async componentDidMount() {
        if (this.state.officeNumber) {
            await this.submitRequest();
        }
    }

    handleChange = async (newAddress, latitude, longitude) => {
        this.setState({
            address: newAddress,
            latitude: latitude,
            longitude: longitude
        });
        if (!newAddress) {
            // had to force the fetch to not use lat,lng,radius due to a race condition in the async call and when state
            // is finally propagated
            await this.fetchJobs(null);
        }
    };

    handleSubmit = async () => {
        await this.submitRequest();
    }

    handleMoreInformation = async (a, b) => {
        this.props.history.push('/' + a + '/' + b);
    }

    setSelectedKeywords = async (event) => {
        this.setState({
            keywords: event.target.value
        });

    }

    handleSelectRadiusChange = async (event) => {
        this.setState({
            radius: event.target.value
        });
    }

    async submitRequest() {
        let requestOptions;
        if (this.state.latitude
            && this.state.longitude
            && this.state.radius) {
            console.log(`Fetching by lat,lng,radius ${this.state.latitude}, ${this.state.longitude}, ${this.state.radius}`);
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    latitude: this.state.latitude,
                    longitude: this.state.longitude,
                    distance: parseInt(this.state.radius)
                })
            };
        }

        await this.fetchJobs(requestOptions);
    }

    async fetchJobs(requestOptions) {
        this.setState({
            loading: true
        });

        if (!requestOptions) {
            requestOptions = {
                method: 'GET'
            };
        }

        await fetch(`/api/jobboard/jobs/${this.state.officeNumber}`, requestOptions)
            .then(async (response) => {
                let isJson = response.headers.get('content-type')?.includes('application/json');
                let data = isJson && await response.json();

                if (!response.ok) {
                    let error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }

                this.setState({ searchResults: data });
            })
            .catch(error => {
                console.log(error);
                alert("failed to call the api");
            });

        this.setState({ loading: false });
    }

    returnNoResults() {
        return (
            <Card id="card-job-posting-no-results-found" variant="elevation" elevation={5} style={{ marginTop: '1ch', textAlign: 'center' }}>
                <Typography color="textSecondary" variant="h5" component="h2" gutterbottom>
                    No results found
                </Typography>
            </Card>);
    }

    returnSearchResults() {
        if (this.state.searchResults?.length === 0) {
            this.returnNoResults();
        }
        else {
            let jobPostings = this.state.searchResults.filter((data) => {
                if (this.state.keywords == null) {
                    return data;
                } else if (
                    data.job_title.toLowerCase().includes(this.state.keywords.toLowerCase())
                    || data.job_description.toLowerCase().includes(this.state.keywords.toLowerCase())) {
                    return data;
                }
            }).map(data => {
                return (
                    <Card key={data.id} id={"card-job-posting-" + data.id} variant="elevation" elevation={5} style={{ marginTop: '1ch' }}>
                        <CardContent>
                            <Typography variant="h5" component="h2" gutterbottom>
                                <Link
                                    component="button"
                                    variant="h5"
                                    onClick={() => this.handleMoreInformation(data.office_number, data.id)}>
                                    {data.job_title.replace(/(<([^>]+)>)/gi, "")}
                                </Link>
                            </Typography>
                            <Typography color="textSecondary" variant="subtitle2" gutterbottom>
                                {data.job_location}
                            </Typography>
                            <Typography color="textSecondary" variant="body1" component="h1">

                                <ReactReadMoreReadLess
                                    charLimit={250}
                                    readMoreText={<Button size="small" color="secondary">Read more &gt;&gt;</Button>}
                                    readLessText={<Button size="small" color="secondary">&lt;&lt; Read less</Button>}
                                    readMoreClassName="read-more-less--more"
                                    readLessClassName="read-more-less--less"

                                >
                                    {data.job_description.replace(/(<([^>]+)>)/gi, "")}
                                </ReactReadMoreReadLess>
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" size="small" color="primary" onClick={() => this.handleMoreInformation(data.office_number, data.id)}>More Information</Button>
                        </CardActions>
                    </Card>)
            });

            return jobPostings.length === 0
                ? this.returnNoResults()
                : jobPostings;
        }
    }

    render() {
        return (
            <div style={{ paddingBottom: '10px' }}>
                <Card variant="elevation" style={{ margin: 'auto', width: '100%', maxWidth: '100ch' }}>
                    <Grid container>
                        <Grid item xs={3}>
                            <Paper elevation={0} style={{ marginLeft: '1ch' }}>
                                <TextField
                                    id="input-keywords"
                                    label="Keywords"
                                    helperText="e.g. Accounting, Office Manager..."
                                    value={this.state.keywords}
                                    onChange={this.setSelectedKeywords} />
                            </Paper>
                        </Grid>
                        <Grid item xs={9}>
                            <Paper elevation={0}>
                                <GoogleMaps
                                    onSelectRadiusChange={this.handleSelectRadiusChange}
                                    onAddressChange={this.handleChange}
                                    {...this.state}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                    <CardActions >
                        <Grid container>
                            <Grid item xs={10}></Grid>
                            <Grid item xs>
                                <Button
                                    style={{ width: "100%" }}
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    onClick={this.handleSubmit}>
                                    Search
                                </Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>

                {
                    this.state.loading ? <LinearProgress /> : this.returnSearchResults()
                }
            </div>
        );
    }
}