import React, { Component } from 'react';
import {
    Button,
    CardContent,
    Typography,
    Card,
    CardActions,
    LinearProgress,
    Divider,
    
} from '@material-ui/core';
import sanitizeHtml from 'sanitize-html';
import {
    Email,
    Call
 } from '@material-ui/icons';


export class JobDetails extends Component {
    static displayName = JobDetails.name;
    constructor(props) {
        super(props);
        this.state = {
            jobdetails: null,
            officeNumber: this.props.match.params.officeNumber,
            id: this.props.match.params.id,
            loading: true
        }
    }

    async componentDidMount() {
        let requestOptions;
        requestOptions = {
            method: 'GET'
        };
        await fetch(`/api/jobboard/jobs/${this.state.officeNumber}/${this.state.id}`, requestOptions)
            .then(async (response) => {
                let isJson = response.headers.get('content-type')?.includes('application/json');
                let data = isJson && await response.json();
                if (!response.ok) {
                    let error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                this.setState({
                    jobdetails: data,
                    loading: false
                });
            })
            .catch(error => {
                console.log(error);
                alert("failed to call the api");
            });

    }
    getback = () => {
        this.props.history.goBack();
    }
    returnNoResults() {
        return (
            <Card id="card-job-posting-no-data-found" variant="elevation" elevation={5} style={{ marginTop: '1ch', textAlign: 'center' }}>
                <Typography color="textSecondary" variant="h5" component="h2" gutterBottom>
                    No Data found
                </Typography>
            </Card>);
    }

    returnDetailIfNotEmpty(title, value, color, variant){
        if (value){
            return (<Typography color={color} variant={variant} gutterBottom>{title ? title + ": " : ""}{value}</Typography>)
        }
        else{
            return;
        }
    }

    returnjobResult() {
        if (this.state.jobdetails?.length === 0) {
            this.returnNoResults();
        }
        else {
            return (
                <Card variant="elevation" style={{ margin: 'auto', width: '100%', maxWidth: '100ch', border: 'none', boxShadow: 'none' }}>
                    <CardContent>
                        <Typography color="textPrimary" variant="h5" component="h2" gutterBottom> {this.state.jobdetails.job_title.replace(/(<([^>]+)>)/gi, "")}</Typography>
                        <Divider />
                        {this.returnDetailIfNotEmpty("Location", this.state.jobdetails.job_location, "textSecondary", "subtitle2")}
                        {this.returnDetailIfNotEmpty("Industry", this.state.jobdetails.job_industry, "textSecondary", "subtitle2")}
                        {this.returnDetailIfNotEmpty("Type", this.state.jobdetails.job_type, "textSecondary", "subtitle2")}
                        {this.returnDetailIfNotEmpty("Skills", this.state.jobdetails.job_skills, "textSecondary", "subtitle2")}
                        <Divider />
                        <Typography color="textSecondary" variant="body1" component="h1" style={{ padding: '1ch 0'}}><div dangerouslySetInnerHTML={{ __html: sanitizeHtml(this.state.jobdetails.job_description) }} /></Typography>
                        <Divider />
                        <Typography color="textSecondary" variant="subtitle1" gutterBottom>Office: {this.state.jobdetails.office_name}</Typography>
                        <Typography color="textSecondary" variant="subtitle1" gutterBottom>Contact: {this.state.jobdetails.contact_name}</Typography>
                        <Typography color="textSecondary" variant="subtitle2" gutterBottom>
                            <Button startIcon={<Call />} href={"tel:" + this.state.jobdetails.contact_telephone}>Call Us</Button>
                            <Button startIcon={<Email />} href={"mailto:" + this.state.jobdetails.application_email}>Email Us</Button>
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" color="primary" onClick={() => this.getback()}>BACK</Button>
                        <Button variant="contained" color="secondary" onClick={() => window.open(this.state.jobdetails.application_url, "_blank")}>Apply Now</Button>
                    </CardActions>
                </Card>
            )
        }
    }

    render() {
        return (
            <div>
                {
                    this.state.loading ? <LinearProgress /> : this.returnjobResult()
                }
            </div>
        )
    }
}