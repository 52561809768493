import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { JobDetails } from './components/JobDetails';
import { 
  ThemeProvider,
  createTheme
} from '@material-ui/core/styles';

export default class App extends Component {
  static displayName = App.name;

  render() {
    const theme = createTheme({
      palette: {
        primary: {
          light: '#0096D6',
          main: '#0077c0',
          dark: '#005288',
          contrastText: '#fff'
        },
        secondary: {
          main: '#6639b7',
          contrastText: '#fff'
        },
      }
    });

    return (
      <ThemeProvider theme={theme}>
        <Layout>
          <Route exact path='/:officeNumber' component={Home} />
          <Route path='/:officeNumber/:id' component={JobDetails} />
        </Layout>
      </ThemeProvider>
    );
  }
}
