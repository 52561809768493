import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    Room,
    LocationOn
} from '@material-ui/icons';
import {
    TextField,
    Grid,
    Typography,
    MenuItem,
    Tooltip,
    IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null };
const geocoderService = { current: null };

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

export default function GoogleMaps(props) {
    const classes = useStyles();
    const [inputValue, setInputValue] = React.useState(props.address);
    const [options, setOptions] = React.useState([]);
    const loaded = React.useRef(false);

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${window._env_.REACT_APP_MAPS_KEY}&libraries=places`,
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    const fetchGeocoder = React.useMemo(
        () =>
            throttle((request, callback) => {
                geocoderService.current.geocode(request, callback);
            }, 200),
        [],
    );

    const fetchAutocomplete = React.useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 200),
        [],
    );

    let initializeGeocoder = () => {
        if (!geocoderService.current && window.google) {
            geocoderService.current = new window.google.maps.Geocoder();
        }

        if (!geocoderService.current) {
            console.log("geocoderService was not initialized properly");
            return undefined;
        }
    }

    let initializeAutocomplete = () => {
        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }

        if (!autocompleteService.current) {

            console.log("autocompleteService was not initialized properly");
            return undefined;
        }
    }

    React.useEffect(() => {
        let active = true;

        initializeAutocomplete();
        initializeGeocoder();

        if (inputValue === '') {
            setOptions(props.address ? [props.address] : []);
            return undefined;
        }

        fetchAutocomplete({ input: inputValue }, (results) => {
            if (active) {
                let newOptions = [];

                if (props.address) {
                    newOptions = [props.address];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [props.address, inputValue, fetchAutocomplete]);

    let handleUseMyLocation = async (e) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                initializeGeocoder();
                fetchGeocoder(({
                    location: {
                        lat: parseFloat(position.coords.latitude),
                        lng: parseFloat(position.coords.longitude)
                    }
                }), results => {
                    if (results) {
                        props.onAddressChange(
                            results[0].formatted_address,
                            results[0].geometry.location.lat(),
                            results[0].geometry.location.lng());
                    } else {
                        props.onAddressChange(null, null, null);
                    }
                });
            })
        }
    }

    let handleSelectRadiusChange = async (e) => {
        props.onSelectRadiusChange(e);
    }

    let handleChange = async (address) => {
        initializeGeocoder();
        if (address) {
            fetchGeocoder({
                address: address.description
            }, results => {
                if (results) {
                    props.onAddressChange(
                        results[0].formatted_address,
                        results[0].geometry.location.lat(),
                        results[0].geometry.location.lng());
                }
            });
        } else {
            props.onAddressChange(null, null, null);
        }
    }

    return (
        <Grid container>
            <Grid item xs={2}>
                <Tooltip title="Use My Location">
                    <IconButton
                        style={{ marginTop: 8 }}
                        color="secondary"
                        onClick={handleUseMyLocation}>
                        <Room />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item xs={6}>
                <Autocomplete
                    id="google-map-demo"
                    style={{ width: 300 }}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                    filterOptions={(x) => x}
                    options={options}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    value={props.address}
                    onChange={(event, newValue) => {
                        setOptions(newValue ? [newValue, ...options] : options);
                        handleChange(newValue);
                    }}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Search near me..." helperText="123 Some Street..." fullWidth />
                    )}
                    renderOption={(option) => {
                        if (option.structured_formatting) {
                            const matches = option.structured_formatting.main_text_matched_substrings;
                            const parts = parse(
                                option.structured_formatting.main_text,
                                matches.map((match) => [match.offset, match.offset + match.length]),
                            );

                            return (
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <LocationOn className={classes.icon} />
                                    </Grid>
                                    <Grid item>
                                        {parts.map((part, index) => (
                                            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                {part.text}
                                            </span>
                                        ))}

                                        <Typography variant="body2" color="textSecondary">
                                            {option.structured_formatting.secondary_text}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            );
                        }
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    id="select-radius"
                    select
                    style={{ width: '80%', marginLeft: '1ch', marginRight: '1ch' }}
                    label="Radius"
                    helperText="in KM"
                    value={props.radius}
                    onChange={handleSelectRadiusChange}
                >
                    <MenuItem value={"Unlimited"}><em>Unlimited</em></MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={250}>250</MenuItem>
                </TextField>
            </Grid>
        </Grid>

    );
}
